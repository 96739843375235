import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `0`,
    }}
  >
    <div className="custom-header">
      <Link
        to="/"
        className="_logo__initial"
        style={{
          textDecoration: `none`,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="css-helpquit-header"></div>
        {siteTitle}
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
