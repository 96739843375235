import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Navigation() {
  return (
    <StaticQuery
      query={graphql`
        query {
          link: allContentfulMenuFooter {
            edges {
              node {
                name
                linked {
                  url
                  id
                  name
                  targetAttribute
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {data.link.edges.map((edge, i) => {
            return (
              <div key={i} className="css-mobile-bottom-nav">
                <div className="css-title-nav css-bottom-15">
                  {edge.node.name}
                </div>

                {edge.node.linked.slice(0, 4).map((edge, i) => {
                  return (
                    <div key={i} className="css-nav">
                      <a
                        href={edge.url}
                        rel="noreferrer"
                        style={{ paddingBottom: 0, paddingRight: 15 }}
                        target={edge.targetAttribute}
                        key={edge.id}
                      >
                        {edge.name}
                      </a>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </>
      )}
    />
  )
}
