import React from "react"
import Navigation from "./Navigation"

const Footer = () => (
  <footer>
    <div className="css-logo-footer">
      <div className="css-helpquit-footer"></div>
    </div>
    <div className="css-fg">
      <Navigation />
    </div>
    <div className="css-copy">
      © {new Date().getFullYear()}, Helpquit France.
    </div>
  </footer>
)

export default Footer
